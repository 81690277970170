@import 'assets/styles';

.page {
  text-align: center;
  padding: 20px;
  position: relative;
}

.title {
  margin: 0 0 20px;
  font-weight: normal;
  font-size: 24px;
}

.serviceName {
  font-size: 14px;
  margin-bottom: 20px;
}

.formControl {
  display: block;
  margin-bottom: 20px;
  text-align: left;

  span {
    display: block;
    margin-bottom: 12px;
  }
}

.formControlField {
  width: 100%;
  height: 50px;
  border: 1px solid var(--tg-theme-hint-color, $hint-color);
  border-radius: var(--border-radius);
  padding: 0 20px;

  &::placeholder {
    color: var(--tg-theme-hint-color, $hint-color);
  }

  &:focus {
    outline: none;
    border-color: var(--tg-theme-button-color, $button-color);
  }
}

.formControlTextarea {
  height: 100px;
  padding: 20px;
}

.formSubmitButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  color: var(--tg-theme-button-text-color, $button-text-color);
  background-color: var(--tg-theme-button-color, $button-color);
  text-decoration: none;
  padding: 20px 30px;
  border-radius: var(--border-radius);
  appearance: none;
  border: none;

  &:disabled {
    opacity: .4;
  }
}

.formControlError {
  margin-top: 10px;
  color: red;
  font-size: 14px;
}

.error {
  color: red;
}
