@import 'assets/styles';

.items {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item {
  a {
    display: block;
    text-align: center;
    color: var(--tg-theme-button-text-color, $button-text-color);
    background-color: var(--tg-theme-button-color, $button-color);
    text-decoration: none;
    padding: 20px 30px;
    border-radius: 0.75rem;
  }
}
