:root {
  --font-family: #{$font-family};

  --color-scheme: #{$color-scheme};
  --bg-color: #{$bg-color};
  --text-color: #{$text-color};
  --hint-color: #{$hint-color};
  --link-color: #{$link-color};
  --button-color: #{$button-color};
  --button-text-color: #{$button-text-color};
  --secondary-bg-color: #{$secondary-bg-color};
  --viewport-height: #{$viewport-height};
  --viewport-stable-height: #{$viewport-stable-height};

  --border-radius: 12px;
}
