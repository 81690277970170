.wrapper {
  margin-top: 30px;
}

.text {
  text-align: center;
  margin: 0 0 10px 0;
}

.number {
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  margin: 0;
}
