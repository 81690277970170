@import 'assets/styles';

.button {
  display: block;
  text-align: center;
  color: var(--tg-theme-button-text-color, $button-text-color);
  background-color: var(--tg-theme-button-color, $button-color);
  text-decoration: none;
  padding: 20px 30px;
  border-radius: 0.75rem;
  appearance: none;
  border: none;
  cursor: pointer;
  position: fixed;
  top: calc(var(--tg-viewport-height) - 58px);
  left: 0;
  width: 100%;
  border-radius: 0;
  transition: all .1s ease-in-out;
}
